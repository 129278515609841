/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

console.debug("process.env.NODE_ENV:", process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
  // dev.jsx
  console.log("Development mode enabled. Dev-specific code is running.");

  // 放置開發環境特定的邏輯，比如攔截 console 警告等
  const originalWarn = console.warn;
  const originalError = console.error;
  // 把需要排除的訊息使用regex的方式為列表
  const excludedWarnings = [
    /findDOMNode is deprecated/,
    /Support for defaultProps will be removed from function components in a future major release./,
    /componentWillMount has been renamed,/,
    /Each child in a list should have a unique "key" prop/,
  ];
  console.warn("載入 dev.jsx:排除特定警告", excludedWarnings);
  console.warn = (...args) => {
    if (
      typeof args[0] === "string" &&
      excludedWarnings.some(regex => regex.test(args[0]))
    ) {
      return;
    }
    originalWarn(...args);
  };

  console.error = (...args) => {
    if (
      typeof args[0] === "string" &&
      excludedWarnings.some(regex => regex.test(args[0]))
    ) {
      return;
    }
    originalError(...args);
  };
  console.warn("[開發模組]:載入成功");
}
